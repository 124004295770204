const breadCrumb = "Certificado de Conclusão - Emissão de Certificado";

const routes = [
  {
    path: "/relacao-de-alunos-concludentes",
    name: "relacao-de-alunos-concludentes",
    meta: {
      allowedPermissions: ["relatorio_alunos_concludentes"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/RelatorioAlunosConcludentes/RelatorioAlunosConcludentesHome.vue"
      ),
  },
];

export default routes;
