<template>
  <div class="card">
    <form @submit.prevent="setDadosTabela">
      <div class="card-header align-items-center">
        <h3>{{ nomeEnsino }}</h3>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead class="fw-bold text-primary border-bottom-2 border-primary">
              <tr>
                <th class="ps-5">Turno</th>
                <th
                  v-for="({ turno }, index) in header"
                  :key="turno + index"
                  class="ps-5"
                >
                  {{ turno }}
                </th>
              </tr>
            </thead>
            <tbody class="border">
              <tr
                v-for="({ title, values }, index) in body"
                :key="title + index"
              >
                <td class="pt-5 ps-5">
                  {{ title }}
                </td>
                <td
                  v-for="({ turno }, index) in header"
                  :key="index"
                  class="ps-5"
                >
                  <div v-for="value in values" :key="value.id">
                    <InputCustom
                      v-if="turno == value.fase"
                      v-model="form.input[`id_${value.id}`]"
                      class="p-1 widthInput"
                      v-mask="mask"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button type="submit" class="btn btn-primary">Salvar</button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { reactive, toRefs, defineProps, defineEmits, computed } from "vue";
import CommonProps from "./Props/CommonProps";
import { turno } from "@/core/listas/turnoList";

const props = defineProps(CommonProps);
const { dataListTable, customProps, nomeEnsino } = toRefs(props);

const emit = defineEmits(["dadosTabela"]);

const body = computed(() => {
  const bodyTable = setBodyTable(dataListTable.value);
  return bodyTable;
});

const header = computed(() => {
  const headerTable = setHeaderTable(dataListTable.value);
  const filteredArray: any = multiDimensionalUnique(headerTable);
  return filteredArray.sort((a, b) => {
    return ("" + a.turno).localeCompare(b.turno);
  });
});

function multiDimensionalUnique(headerTable: any) {
  var arrayDeValoresUnicos = [] as any;
  var itemAchado = {};
  for (var i = 0, l = headerTable.length; i < l; i++) {
    var stringified = JSON.stringify(headerTable[i].turno);
    if (itemAchado[stringified]) {
      continue;
    }
    arrayDeValoresUnicos.push(headerTable[i]);
    itemAchado[stringified] = true;
  }
  return arrayDeValoresUnicos;
}

const data = reactive({
  form: {
    input: [] as any,
  },
});

function setDadosTabela(event) {
  const convertedData = converterDadosParaEnviar(data.form);
  emit("dadosTabela", convertedData, event.target);
}

function converterDadosParaEnviar(dados) {
  const dataToSendToBack = [] as any[];
  for (const key in dados.input) {
    const dadosConvertidos = key.split("_");
    dataToSendToBack.push({
      id: dadosConvertidos[1],
      value: dados.input[key],
    });
  }
  return dataToSendToBack;
}

function setHeaderTable(dadosDoBanco) {
  return dadosDoBanco.map((e) => {
    return {
      turno: e.fase,
    };
  });
}

function setBodyTable(dadosDoBanco) {
  const dadosDoBancoMapeados = dadosDoBanco.map((e) => {
    return e.turno;
  });
  const dadosProntosParaOComponent = mapearInputsDaTabela(
    dadosDoBancoMapeados,
    dadosDoBanco
  );
  gerarDataReativaDinamica(dadosProntosParaOComponent);
  return dadosProntosParaOComponent;
}

function gerarDataReativaDinamica(dadosDoBancoMapeados) {
  for (const dados of dadosDoBancoMapeados) {
    for (const valor of dados.values) {
      data.form.input[`id_${valor.id}`] = valor[customProps.value];
    }
  }
}

function mapearInputsDaTabela(dadosDoBancoMapeados, dadosDoBanco) {
  const filteredArray = dadosDoBancoMapeados.filter(
    (data, index) => dadosDoBancoMapeados.indexOf(data) === index
  );
  return filteredArray.map((turnoFiltered) => {
    let filteredData = dadosDoBanco.filter((bodyTurno) => {
      return bodyTurno.turno === turnoFiltered;
    });
    let returnTitle = "";
    if (typeof turnoFiltered == "string") {
      returnTitle = turno.filter((e) => e.label == turnoFiltered)[0].label;
    } else {
      returnTitle = turno.filter((e) => e.value == turnoFiltered)[0].label;
    }
    return {
      title: returnTitle,
      values: [...filteredData],
    };
  });
}

const { form } = toRefs(data);
</script>

<style scoped>
.widthInput {
  width: 80%;
}
</style>
