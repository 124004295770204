<template>
  <router-link :to="to" class="btn btn-primary btn-sm" title="Cadastrar">
    <i class="bi bi-plus-circle-fill"></i> {{ label }}
  </router-link>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "botao-cadastrar",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "Novo Cadastro",
    },
  },
});
</script>
