import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.rotaId)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(_ctx.classe),
        to: {
      name: _ctx.rotaEdit,
      params: { id: _ctx.rotaId },
    }
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", null, [
            (_ctx.iconClass !== '')
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(_ctx.iconClass)
                }, null, 2 /* CLASS */))
              : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.btnText), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "to"]))
    : _createCommentVNode("v-if", true)
}