import { App } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Router from "@/router/clean";

/**
 * @description Serviço para chamar mensagem
 */
class MensagemService {
  /**
   * @description Propriedade para compartilhar instância vue
   */
  public static vueInstance: App;

  /**
   * @description Inicializa vue router
   */
  public static init(app: App<Element>) {
    MensagemService.vueInstance = app;
  }

  /**
   * @description Exibe mensagem de sucesso
   * @param texto: string
   * @param rota?: string
   * @returns void
   */
  //  texto = "Cadastro efetuado com sucesso!" as string,
  //   rota?: string
  public static async sucesso({
    texto = "Cadastro efetuado com sucesso!" as string,
    rota = "" as string,
  }) {
    return Swal.fire({
      text: texto,
      icon: "success",
      buttonsStyling: false,
      allowOutsideClick: false,
      confirmButtonText: "Continuar!",
      customClass: {
        confirmButton: "btn fw-semibold btn-light-primary",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);
    });
  }

  public static async sucessoNotRedirect({
    texto = "Cadastro efetuado com sucesso!" as string,
  }) {
    return Swal.fire({
      text: texto,
      icon: "success",
      buttonsStyling: false,
      allowOutsideClick: false,
      confirmButtonText: "Continuar!",
      customClass: {
        confirmButton: "btn fw-semibold btn-light-primary",
      },
    }).then(() => {
      //
    });
  }

  public static async sucesso_beckeground({
    titulo = "Ação iniciada" as string,
    texto = "Cadastro efetuado com sucesso!" as string,
    rota = "" as string,
  }) {
    return Swal.fire({
      text: texto,
      title: titulo,
      icon: "success",
      buttonsStyling: false,
      allowOutsideClick: false,
      confirmButtonText: "Continuar!",
      customClass: {
        confirmButton: "btn fw-semibold btn-light-primary",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);
    });
  }

  /**
   * @description Exibe mensagem de erro
   * @param texto: string
   * @param rota?: string
   * @returns void
   */
  public static async erro_custom({
    texto = "Falha ao atualizar!" as string,
    rota = "" as string,
    buttonTitle = "" as string,
  }) {
    return Swal.fire({
      text: texto || "Ocorreu um erro",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: buttonTitle,
      customClass: {
        confirmButton: "btn fw-semibold btn-danger",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);
    });
  }

  public static async atencao({
    titulo = "Atenção!" as string,
    texto = "Cadastro efetuado com sucesso!" as string,
    rota = "" as string,
  }) {
    return Swal.fire({
      text: texto,
      title: titulo,
      icon: "warning",
      buttonsStyling: false,
      allowOutsideClick: false,
      confirmButtonText: "Fechar",
      customClass: {
        confirmButton: "btn fw-semibold btn-primary",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);
    });
  }

  public static async sucesso_refresh_page({
    texto = "Cadastro efetuado com sucesso!" as string,
    rota = "" as string,
  }) {
    return Swal.fire({
      text: texto,
      icon: "success",
      buttonsStyling: false,
      allowOutsideClick: false,
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "btn fw-semibold btn-success",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);

      location.reload();
    });
  }

  /**
   * @description Exibe mensagem de erro
   * @param texto: string
   * @param rota?: string
   * @returns void
   */
  public static async erro({
    texto = "Falha ao atualizar!" as string,
    rota = "" as string,
    confirmButtonText = "Tente Novamente" as string,
  }) {
    return Swal.fire({
      text: texto || "Ocorreu um erro",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: "btn fw-semibold btn-danger",
      },
    }).then(() => {
      if (`${rota}` != "") Router.push(`${rota}`);
    });
  }

  /**
   * @description Exibe mensagem de alerta para retorno de página
   * @param texto: string
   * @param rota?: string
   * @returns void
   */
  public static async retorno({
    texto = "Tem certeza que deseja voltar? Ao sair, você perderá todos os dados" as string,
    rota = "/" as string,
  }) {
    return Swal.fire({
      text: texto,
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      isDismissed: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn fw-semibold btn-primary",
        cancelButton: "btn fw-semibold btn-danger",
      },
    }).then((result) => {
      if (result.value) {
        Router.push(`${rota}`);
      }
    });
  }

  public static async excluir({
    titulo = "Atenção!" as string,
    texto = "Tem certeza que deseja voltar? Ao sair, você perderá todos os dados" as string,
  }) {
    return Swal.fire({
      text: texto,
      title: titulo,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-primary",
        cancelButton: "btn fw-semibold btn-danger",
      },
    }).then((result) => {
      return result;
    });
  }

  public static async gotoHome({
    texto = "Deseja sair desse módulo?" as string,
    rota = "/" as string,
  }) {
    return Swal.fire({
      text: texto,
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      isDismissed: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      customClass: {
        confirmButton: "btn fw-semibold btn-primary",
        cancelButton: "btn fw-semibold btn-danger",
      },
    }).then((result) => {
      if (result.value) {
        if (`${rota}` != "") {
          window.location.href = `${rota}`;
        }
      }
    });
  }
}

export default MensagemService;
