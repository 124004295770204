const breadCrumb = "Manutenção de Registro de Certificado";

const routes = [
  {
    path: "/manutencao-registro-certificado-geja",
    name: "manutencao-registro-certificado-geja",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificadoGEJA/ManutencaoRegistroCertificadoGEJAHome.vue"
      ),
  },
  {
    path: "/manutencao-registro-certificado-geja/novo",
    name: "manutencao-registro-certificado-geja-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificadoGEJA/ManutencaoRegistroCertificadoGEJARegistrar.vue"
      ),
  },
];

export default routes;
