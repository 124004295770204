const breadCrumb =
  "Certificado de Conclusão - Tabelas Básicas para Certificados e Diplomas";

const routes = [
  {
    path: "/parametros-certificado",
    name: "parametros-certificado",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoParametroCertificado/ManutencaoParametroCertificadoHome.vue"
      ),
  },
  {
    path: "/parametros-certificado/novo",
    name: "parametros-certificado-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_create"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoParametroCertificado/ManutencaoParametroCertificadoCad.vue"
      ),
  },
  {
    path: "/parametros-certificado/editar/:id",
    name: "parametros-certificado-editar",
    meta: {
      allowedPermissions: ["parametro_certificado_edit"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoParametroCertificado/ManutencaoParametroCertificadoEdit.vue"
      ),
  },
];

export default routes;
