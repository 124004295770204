const breadCrumb = "Manutenção de Registro de Certificado";

const routes = [
  {
    path: "/manutencao-registro-certificado",
    name: "manutencao-registro-certificado",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificado/ManutencaoRegistroCertificadoHome.vue"
      ),
  },
  {
    path: "/manutencao-registro-certificado/novo/:solicitacao_id/:aluno_id",
    name: "manutencao-registro-certificado-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificado/ManutencaoRegistroCertificadoRegistrar.vue"
      ),
  },
  {
    path: "/manutencao-registro-certificado/alterar/:solicitacao_id/:aluno_id",
    name: "manutencao-registro-certificado-alterar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificado/ManutencaoRegistroCertificadoAlterar.vue"
      ),
  },
  {
    path: "/manutencao-registro-certificado/cancelar/:solicitacao_id/:aluno_id",
    name: "manutencao-registro-certificado-cancelar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificado/ManutencaoRegistroCertificadoCancelar.vue"
      ),
  },
  {
    path: "/manutencao-registro-certificado/consultar/:solicitacao_id/:aluno_id",
    name: "manutencao-registro-certificado-consultar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRegistroCertificado/ManutencaoRegistroCertificadoConsultar.vue"
      ),
  },
];

export default routes;
