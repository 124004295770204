<template>
  <div class="d-flex align-items-center">
    <div class="dropdown">
      <button
        class="btn btn-light-primary"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="showDropDownMenu()"
      >
        Filtro <i class="bi bi-funnel-fill"></i>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        :class="data.show_drop_down_menu ? 'show-element' : 'not-show-element'"
        aria-labelledby="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded="false"
        style="z-index: 100 !important"
      >
        <CustomSearch
          :fields="fields"
          v-on:aplicar="getFilledData"
          :title="'Opções de Filtro'"
          v-on:close="hideDropDownMenu"
          v-on:selected="getSelectedData"
          v-on:canceled="getCanceled"
          v-on:cleaned="getCleaned"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import CustomSearch from "./CustomSearch.vue";
export default defineComponent({
  name: "custom-filter-button",
  components: {
    CustomSearch,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
  setup() {
    let data = reactive({
      show_drop_down_menu: false,
    });

    return {
      data,
    };
  },

  methods: {
    getFilledData(event) {
      if (!event.length) return; // prevent unknow event
      const customSearch = {};
      for (const key of event) {
        if (key["value"]) {
          customSearch[key["name"]] = key["value"];
        }
      }

      this.$emit("data", customSearch);
      //console.log(event);
    },
    getSelectedData(event) {
      this.$emit("selected", event);
      //console.log(event);
    },
    showDropDownMenu() {
      //this.data.show_drop_down_menu = !this.data.show_drop_down_menu;
      this.data.show_drop_down_menu = true;
    },
    hideDropDownMenu() {
      this.data.show_drop_down_menu = false;
    },
    getCanceled(event) {
      this.$emit("canceled", event);
    },
    getCleaned(event) {
      this.$emit("cleaned", event);
    },
  },
});
</script>

<style>
.s-obrigatorio,
small {
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.show-element {
  display: block;
  right: 0;
  left: auto;
}
.not-show-element {
  display: none;
}
</style>
