<template>
  <div class="dropdown position-static">
    <button
      class="btn btn-sm btn-primary dropdown-toggle"
      type="button"
      id="dropdownAcoesButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Ações
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownAcoesButton">
      <li v-for="(acao, index) in acoes" :key="acao.label + index">
        <router-link
          class="dropdown-item"
          :to="{
            name: acao.nomeRota,
            params: { id: id },
          }"
        >
          {{ acao.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ActionInterface from "../Tabela/Interface/ActionInterface";

export default defineComponent({
  name: "BotaoAcoes",
  props: {
    acoes: {
      type: Array as PropType<ActionInterface[]>,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
});
</script>
