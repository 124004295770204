const breadCrumb =
  "Certificado de Conclusão - Tabelas Básicas para Certificados e Diplomas";

const routes = [
  {
    path: "/tipo-certificado",
    name: "tipo-certificado",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificado/ManutencaoTipoCertificadoHome.vue"
      ),
  },
  {
    path: "/tipo-certificado/novo",
    name: "tipo-certificado-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_create"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificado/ManutencaoTipoCertificadoCad.vue"
      ),
  },
  {
    path: "/tipo-certificado/editar/:id",
    name: "tipo-certificado-editar",
    meta: {
      allowedPermissions: ["parametro_certificado_edit"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificado/ManutencaoTipoCertificadoEdit.vue"
      ),
  },
  {
    path: "/tipo-certificado/visualizar/:id",
    name: "tipo-certificado-visualizar",
    meta: {
      allowedPermissions: ["parametro_certificado_edit"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificado/ManutencaoTipoCertificadoVisualizar.vue"
      ),
  },
];

export default routes;
