import SelectData from "../interface/IInputSelect";
import { PropType } from "vue";

export default {
  modelValue: {
    type: [String, Number],
    required: false,
    default: "",
  },
  type: {
    type: [String, Number],
    required: false,
    default: "text",
  },
  options: {
    type: Array as PropType<SelectData[]>,
    default: () => [],
  },
};
