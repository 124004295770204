import axios from "axios";
import KeycloakService from "@/core/services/KeycloakService";

async function obterMenus() {
  try {
    const token = process.env.VUE_APP_JWT_TOKEN;
    const keycloakToken = KeycloakService.getInstance().token;

    const apiUrl = `${process.env.VUE_APP_ADMIN_API}/perfil/menu/${process.env.VUE_APP_MODULO_UUID}`;

    const result = await axios.get(apiUrl, {
      headers: {
        "x-autenticacao": `Bearer ${keycloakToken}`,
        Authorization: `Bearer ${token}`,
        "X-Apikey": `Bearer ${token}`,
      },
    });

    const dados = result.data?.dados;

    // Save the data to local storage
    localStorage.setItem("menuData", JSON.stringify(dados));

    return dados;
  } catch (error) {
    return []; // Return an empty array in case of an error
  }
}

export default obterMenus;
