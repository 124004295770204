import { Modal } from "bootstrap";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

function exibeOverlay() {
  if (document.getElementById("overlay")) {
    return;
  }

  const indicador = document.createElement("div");
  indicador.id = "overlay";
  indicador.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto; position: absolute; top: 50%; left: 50%;" width="101px" height="101px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#009ef7" stroke-width="3" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/>
        </circle>
    </svg>
  `;
  document.body.prepend(indicador);
}

function removeOverlay() {
  const indicador = document.getElementById("overlay");
  if (indicador) {
    indicador.remove();
  }
}

export { removeModalBackdrop, hideModal, exibeOverlay, removeOverlay };
