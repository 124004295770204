import Keycloak from "keycloak-js";
import KeycloakConfig from "../config/KeycloakConfig";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";

class KeycloakService {
  private keycloak: Keycloak | null = null;

  async init() {
    this.keycloak = new Keycloak(KeycloakConfig);
    const auth = await this.keycloak.init({ onLoad: "check-sso" });
    if (!auth) {
      window.location.href = process.env?.VUE_APP_REDIRECT_LOGIN || "";
    } else {
      this.setUserLogged();
    }
  }

  getInstance() {
    if (!this.keycloak) {
      throw new Error("Keycloak não inicializado");
    }
    return this.keycloak;
  }

  setUserLogged() {
    if (!this.keycloak) {
      throw new Error("Keycloak não inicializado");
    }
    store.commit(Mutations.SET_USER, this.keycloak.tokenParsed);
  }

  logout() {
    const refresh_token = this.keycloak?.refreshToken ?? "";
    const data = new URLSearchParams({
      client_id: "admin-cli",
      refresh_token: refresh_token,
    }).toString();

    fetch(
      process.env.VUE_APP_KEYCLOAK_SERVER_URL +
        "/realms/sigeam/protocol/openid-connect/logout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/x-www-form-urlencoded",
        },
        body: data,
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          location.reload();
        } else {
          console.error("Erro na resposta:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Erro na requisição fetch:", error);
      });
  }
}

export default new KeycloakService();
