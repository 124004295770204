const breadCrumb =
  "Certificado de Conclusão - Tabelas Básicas para Certificados e Diplomas";

const routes = [
  {
    path: "/tipo-certificado-ensino",
    name: "tipo-certificado-ensino",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificadoEnsino/ManutencaoTipoCertificadoEnsinoHome.vue"
      ),
  },
  {
    path: "/tipo-certificado-ensino/novo",
    name: "tipo-certificado-ensino-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_create"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificadoEnsino/ManutencaoTipoCertificadoEnsinoCad.vue"
      ),
  },
  {
    path: "/tipo-certificado-ensino/editar/:id",
    name: "tipo-certificado-ensino-editar",
    meta: {
      allowedPermissions: ["parametro_certificado_edit"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificadoEnsino/ManutencaoTipoCertificadoEnsinoEdit.vue"
      ),
  },
  {
    path: "/tipo-certificado-ensino/visualizar/:id",
    name: "tipo-certificado-ensino-visualizar",
    meta: {
      allowedPermissions: ["parametro_certificado_edit"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoTipoCertificadoEnsino/ManutencaoTipoCertificadoEnsinoVisualizar.vue"
      ),
  },
];

export default routes;
