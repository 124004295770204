import CustomTextArea from "./Inputs/CustomTextArea/CustomTextArea.vue";
import InputCustom from "./Inputs/InputCustom/InputCustom.vue";
import InputSelect from "./Inputs/InputSelect/InputSelect.vue";
import InputDate from "./Inputs/InputDate/InputDate.vue";
import GradeEdicao from "./GradeEdicao/GradeEdicao.vue";
import MunicipioUf from "./Municipios/MunicipioUf.vue";
import CustomFilterButton from "./Search/CustomFilterButton.vue";
import TabelaAccordion from "./TabelaAccordion/TabelaAccordion.vue";
import Tabela from "./Tabela/Tabela.vue";
import TabelaInput from "./Tabela/TabelaInput.vue";
import Paginacao from "./Paginacao/Paginacao.vue";

export {
  CustomTextArea,
  InputCustom,
  InputSelect,
  InputDate,
  GradeEdicao,
  MunicipioUf,
  CustomFilterButton,
  TabelaAccordion,
  Tabela,
  TabelaInput,
  Paginacao,
};
