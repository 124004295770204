import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$attrs.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass({ required: _ctx.$attrs.required })
        }, _toDisplayString(_ctx.$attrs.label), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("select", _mergeProps({
      class: "form-select form-control",
      value: _ctx.modelValue
    }, {
        ..._ctx.$attrs,
        onChange: $setup.onInput,
      }), [
      _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.$attrs.placeholder || "Selecione uma das Opções"), 1 /* TEXT */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ value, label }, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: value
        }, _toDisplayString(label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 16 /* FULL_PROPS */, _hoisted_1)
  ]))
}