const convertDate = (date?: number): string => {
  if (date == undefined) {
    return "";
  }
  return new Date(date * 1000).toISOString().split("T")[0];
};

const convertDateIso = (date?: string): string => {
  if (date == undefined) {
    return "";
  }
  return new Date(date).toISOString().split("T")[0];
};
const convertDateBrComHora = (date?: number) => {
  if (date == undefined) {
    return "";
  }
  return new Date(date * 1000).toLocaleString("en-GB");
};

const convertDateBr = (date?: number) => {
  if (date == undefined) {
    return "";
  }

  const myDate = new Date(date * 1000);
  return (
    myDate.getUTCDate() +
    "/" +
    (myDate.getUTCMonth() + 1 + "").padStart(2, "0") +
    "/" +
    myDate.getUTCFullYear()
  );
  // return new Date(date * 1000).toLocaleDateString("en-GB");
};

const convertDateUs = (date?: number) => {
  if (date == undefined) {
    return "";
  }
  return new Date(date * 1000).toLocaleDateString();
};

const convertStringDateToBr = (date?: string) => {
  if (date == undefined) {
    return "";
  }
  return new Date(date).toLocaleDateString();
};

const getFullYear = () => {
  const dataAtual = new Date();
  return dataAtual.getFullYear();
};

const tomorrowDate = () => {
  const tomorrow = new Date();
  const timestamp = tomorrow.setDate(tomorrow.getDate() + 1);
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return year + "-" + month + "-" + day;
};
export {
  convertDate,
  convertDateBr,
  convertDateBrComHora,
  convertDateIso,
  convertDateUs,
  convertStringDateToBr,
  getFullYear,
  tomorrowDate,
};
