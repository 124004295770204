<template>
  <button :class="classe">
    {{ text }}
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BotaoComponent",
  props: {
    text: {
      type: String,
      required: false,
      default: "Titulo Botão",
    },
    classe: {
      type: String,
      required: false,
      default: "btn btn-sm btn-primary",
    },
  },
});
</script>
