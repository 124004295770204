<template>
  <div class="table-responsive">
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
    <div
      class="accordion"
      id="accordionExample"
      v-if="!loading && listas.length"
    >
      <div class="accordion-item" v-for="(lista, index) in listas" :key="index">
        <h2 class="accordion-header" :id="'headingTwo' + index">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapseTwo' + index"
            aria-expanded="false"
            :aria-controls="'collapseTwo' + index"
          >
            <table
              class="table table-bordered table-striped table-hover p-0 m-0"
            >
              <thead class="fw-bold">
                <tr class="p-0 m-0">
                  <th
                    scope="col"
                    class="w-100px p-0 m-0"
                    v-for="dado in dadosTabela"
                    :key="dado.campo"
                  >
                    <span class="activated">{{ lista[dado.campo] }}</span>
                  </th>
                </tr>
              </thead>
            </table>
          </button>
        </h2>
        <div
          :id="'collapseTwo' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'headingTwo' + index"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <slot
              name="tabelaaccordion"
              v-bind:tabelaaccordion="lista"
              :id="'slot' + index"
            >
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="text-center">Nenhum Registro Encontrado</h3>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, PropType } from "vue";
import ActionInterface from "./Interface/ActionInterface";

export default defineComponent({
  name: "tabela-listagem",
  components: {},
  props: {
    listas: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    cabecalhoTabela: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    dadosTabela: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      required: false,
    },
    rotaEdit: {
      type: String,
      required: false,
      default: "",
    },
    acoes: {
      type: Array as PropType<ActionInterface[]>,
      required: false,
      default: () => [],
    },
    hideAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const rota = reactive({
      rotaId: "",
    });

    return { rota: rota.rotaId };
  },
});
</script>
<style scoped>
[class="accordion-button"] .activated {
  color: #009ef7 !important;
  background-color: #f5f8fa !important;
}
</style>
