import KeycloakService from "@/core/services/KeycloakService";
import { createApp } from "vue";
import App from "./App.vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import i18n from "@/core/plugins/i18n";
import router from "@/router/clean";
import VueMask from "@devindex/vue-mask";
import ElementPlus from "element-plus";
import store from "./store";

//imports for app initialization
import ApiServiceCustom from "./core/services/ApiServiceCustom";

import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import globalComponent from "./globalComponent";

import obterMenus from "@/utils/obterMenus";

import "@/core/plugins/prismjs";
import "bootstrap";

const initializeApp = async () => {
  try {
    await KeycloakService.init();
    await obterMenus();
    const app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(ElementPlus);
    app.use(VueMask);
    app.use(i18n);

    ApiServiceCustom.init(app);
    initApexCharts(app);
    initInlineSvg(app);
    initVeeValidate();

    globalComponent(app);

    app.mount("#app");
  } catch (error) {
    console.error("Erro ao inicializar o aplicativo:", error);
  }
};

initializeApp();
