<template>
  <router-link
    v-if="rotaId"
    :class="classe"
    :to="{
      name: rotaEdit,
      params: { id: rotaId },
    }"
    ><a
      ><i :class="iconClass" v-if="iconClass !== ''"></i> {{ btnText }}</a
    ></router-link
  >
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "acoes-editar-componente",
  props: {
    rotaId: {
      type: Number,
      required: false,
    },
    rotaEdit: {
      type: String,
      required: false,
    },
    classe: {
      type: String,
      required: false,
      default: "btn btn-sm btn-primary",
    },
    iconClass: {
      type: String,
      required: false,
      default: "bi-pencil-fill",
    },
    btnText: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
