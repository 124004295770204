<template>
  <div :class="ufClass" v-if="isAvailableShow">
    <InputSelect
      :label="`${ufLabel}:`"
      name="uf"
      v-model="uf"
      @change="onChangeCarregarMunicipios"
      placeholder="Selecione uma das opções"
      :required="required"
      :options="stateList"
      :disabled="disableMod"
    />
  </div>
  <div :class="municipioClass" v-if="isAvailableShow">
    <InputSelect
      :label="`${municipioLabel}:`"
      name="municipio_id"
      v-model="municipio"
      :disabled="(isFilter && !municipios.length) || disableMod"
      @change="onChange"
      placeholder="Selecione uma das opções"
      :required="requiredMunicipio !== undefined ? requiredMunicipio : required"
      :options="municipios"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  defineProps,
  defineEmits,
  reactive,
  toRefs,
  computed,
  watch,
} from "vue";
import { stateList as states } from "@/core/listas/stateList";
import axios from "axios";

const emit = defineEmits(["changeUf", "update:modelValue", "async-complete"]);

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: false,
    default: "",
  },
  municipioLabel: {
    type: String,
    default: "Município",
  },
  ufLabel: {
    type: String,
    default: "UF",
  },
  isAvailableShow: {
    type: Boolean,
    default: true,
  },
  municipioClass: {
    type: String,
    default: "col-sm-4",
  },
  ufClass: {
    type: String,
    default: "col-sm-2",
  },
  currentUf: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  requiredMunicipio: {
    type: Boolean,
    default: undefined,
  },
  isFilter: {
    type: Boolean,
    default: false,
  },
  disableMod: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  municipios: [] as any,
  uf: "" as any,
  municipio: "" as any,
});

const { municipios, uf, municipio } = toRefs(data);

const stateList = computed(() => {
  return states;
});

async function onChangeCarregarMunicipios(event) {
  const uf = event?.target?.value || event;
  emit("changeUf", data.uf);
  if (uf) {
    axios
      .get(
        process.env.VUE_APP_ORGANIZACAO_GEOGRAFICA_API +
          "/municipio/listagem/" +
          uf
      )
      .then((response) => {
        data.municipios = response.data.dados.map((dado) => {
          return {
            value: dado.id,
            label: dado.nome,
          };
        });
        emit("async-complete", data.municipios);
      })
      .catch((response) => (data.municipios = []));
  }
}

function onChange() {
  return emit("update:modelValue", data.municipio);
}

watch(
  () => props.currentUf,
  (value) => {
    if (value === "clearfilter") {
      data.uf = null;
      data.municipios = [];
      return;
    }
    if (value) {
      data.uf = value;
      onChangeCarregarMunicipios(value);
    }
  }
);

watch(
  () => props.modelValue,
  (modelValue) => {
    data.municipio = modelValue;
  }
);

watch(
  () => props.isAvailableShow,
  (value) => {
    if (!value) {
      data.municipio = "";
      data.uf = "";
    }
  }
);
</script>
