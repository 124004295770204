/* eslint-disable */
// prettier-ignore
const validations = {
  CPF: `[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}`,
  CNPJ: "/^(d{2})(d{3})(d{3})(d{4})(d{2})/",
  INTEIRO: "/^-?d+$/",
  INTEIRO_NEGATIVO: "/^-d+$/",
  DATA: "/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]d{4}$/",
  INTEIRO_MAIOR_1900: "19[0-9][0-9]|20[0-9][0-9]|[2-9][0-9][0-9][0-9]",
  ANO_ENTRE_1900_E_ATUAL: getYearBetween(),
  NOME_ESCOLA: "^[a-zA-Z0-9ªº. '-]+$",
  NOME_COMPLETO: "^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$",
  EMAIL: "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
  TELEFONE: "(^\d{4,5}\-\d{4})$",
  MES: "0?[1-9]|1[012]",
  DIAS_DO_MES: "0?[1-9]|1[031]",
};

function getYearBetween(): string {
  let currentYear = new Date().getFullYear().toString();

  let first = currentYear.substring(2, 3);
  let second = currentYear.substring(3, 4);

  return `^(19[0-9][0-9]|200[0-9]|20[0-${first}][0-9]|20[0-${first}][0-${second}])$`;
}

export { validations };
