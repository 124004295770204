<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import setup from "../setupComposable/setup";
import commonProps from "../props/commonProps";

defineProps(commonProps);
const emit = defineEmits(["update:modelValue"]);
const { onInput } = setup(emit);
</script>

<template>
  <div>
    <label v-if="$attrs.label" :class="{ required: $attrs.required }">
      {{ $attrs.label }}
    </label>
    <select
      class="form-select form-control"
      :value="modelValue"
      v-bind="{
        ...$attrs,
        onChange: onInput,
      }"
    >
      <option disabled selected value="">
        {{ $attrs.placeholder || "Selecione uma das Opções" }}
      </option>
      <option
        v-for="({ value, label }, index) in options"
        :key="index"
        :value="value"
      >
        {{ label }}
      </option>
    </select>
  </div>
</template>
