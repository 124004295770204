const stateList = [
  { value: "AC", label: "AC", text: "AC" },
  { value: "AL", label: "AL", text: "AL" },
  { value: "AP", label: "AP", text: "AP" },
  { value: "AM", label: "AM", text: "AM" },
  { value: "BA", label: "BA", text: "BA" },
  { value: "CE", label: "CE", text: "CE" },
  { value: "DF", label: "DF", text: "DF" },
  { value: "ES", label: "ES", text: "ES" },
  { value: "GO", label: "GO", text: "GO" },
  { value: "MA", label: "MA", text: "MA" },
  { value: "MG", label: "MG", text: "MG" },
  { value: "MS", label: "MS", text: "MS" },
  { value: "MT", label: "MT", text: "MT" },
  { value: "PA", label: "PA", text: "PA" },
  { value: "PB", label: "PB", text: "PB" },
  { value: "PR", label: "PR", text: "PR" },
  { value: "PE", label: "PE", text: "PE" },
  { value: "PI", label: "PI", text: "PI" },
  { value: "RJ", label: "RJ", text: "RJ" },
  { value: "RN", label: "RN", text: "RN" },
  { value: "RS", label: "RS", text: "RS" },
  { value: "RO", label: "RO", text: "RO" },
  { value: "RR", label: "RR", text: "RR" },
  { value: "SC", label: "SC", text: "SC" },
  { value: "SP", label: "SP", text: "SP" },
  { value: "SE", label: "SE", text: "SE" },
  { value: "TO", label: "TO", text: "TO" },
];

export { stateList };
