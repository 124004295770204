import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSelect = _resolveComponent("InputSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.isAvailableShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass($props.ufClass)
        }, [
          _createVNode(_component_InputSelect, {
            label: `${$props.ufLabel}:`,
            name: "uf",
            modelValue: $setup.uf,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.uf) = $event)),
            onChange: $setup.onChangeCarregarMunicipios,
            placeholder: "Selecione uma das opções",
            required: $props.required,
            options: $setup.stateList,
            disabled: $props.disableMod
          }, null, 8 /* PROPS */, ["label", "modelValue", "required", "options", "disabled"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($props.isAvailableShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass($props.municipioClass)
        }, [
          _createVNode(_component_InputSelect, {
            label: `${$props.municipioLabel}:`,
            name: "municipio_id",
            modelValue: $setup.municipio,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.municipio) = $event)),
            disabled: ($props.isFilter && !$setup.municipios.length) || $props.disableMod,
            onChange: $setup.onChange,
            placeholder: "Selecione uma das opções",
            required: $props.requiredMunicipio !== undefined ? $props.requiredMunicipio : $props.required,
            options: $setup.municipios
          }, null, 8 /* PROPS */, ["label", "modelValue", "disabled", "required", "options"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}