const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      requireAuth: false,
    },
    component: () => import("@/views/Dashboard.vue"),
  },
];

export default routes;
