import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_2 = { class: "row gy-5" }
const _hoisted_3 = {
  key: 0,
  class: "col-sm-6"
}
const _hoisted_4 = {
  key: 1,
  class: "col-sm-6"
}
const _hoisted_5 = {
  key: 2,
  class: "col-sm-6"
}
const _hoisted_6 = {
  key: 3,
  class: "col-sm-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputCustom = _resolveComponent("InputCustom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.porpHideHr)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.porpHideDataInclusao)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_InputCustom, {
              label: "Data e Hora de Inclusão:",
              disabled: true,
              "model-value": _ctx.dataInclusao
            }, null, 8 /* PROPS */, ["model-value"])
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.porpHideUsuarioInclusao)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_InputCustom, {
              label: "Usuário de Inclusão",
              disabled: true,
              "model-value": _ctx.propPayload.usuario_inclusao,
              placeholder: ""
            }, null, 8 /* PROPS */, ["model-value"])
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.porpHideDataAlteracao)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_InputCustom, {
              label: "Data e Hora de Alteração",
              disabled: true,
              modelValue: _ctx.dataAlteracao,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataAlteracao) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.porpHideUsuarioAlteracao)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_InputCustom, {
              label: "Usuário de Alteração",
              disabled: true,
              "model-value": _ctx.propPayload.usuario_alteracao,
              placeholder: ""
            }, null, 8 /* PROPS */, ["model-value"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}