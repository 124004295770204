const breadCrumb = "Manutenção de Livro de Registro de Certificado";

const routes = [
  {
    path: "/manutencao-de-livro-de-registro-de-certificado",
    name: "manutencao-de-livro-de-registro-de-certificado",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoLivroRegistroCertificado/ManutencaoLivroRegistroCertificadoHome.vue"
      ),
  },
  {
    path: "/manutencao-de-livro-de-registro-de-certificado/consultar/:id",
    name: "manutencao-de-livro-de-registro-de-certificado-consultar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoLivroRegistroCertificado/ManutencaoLivroRegistroCertificadoConsultar.vue"
      ),
  },
  {
    path: "/manutencao-de-livro-de-registro-de-certificado/excluir/:id",
    name: "manutencao-de-livro-de-registro-de-certificado-excluir",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoLivroRegistroCertificado/ManutencaoLivroRegistroCertificadoExcluir.vue"
      ),
  },
  {
    path: "/manutencao-de-livro-de-registro-de-certificado/cadastrar",
    name: "manutencao-de-livro-de-registro-de-certificado-cadastrar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoLivroRegistroCertificado/ManutencaoLivroRegistroCertificadoCadastro.vue"
      ),
  },
];

export default routes;
