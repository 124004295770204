<template>
  <div :class="{ 'card-footer': !removeFooter }" v-if="hasPages.length">
    <ul class="pagination pagination-outline">
      <li
        class="page-item previous m-1"
        :class="{ disabled: paginaAtual == 1 }"
      >
        <span class="page-link" @click="changePage(paginaAtual - 1)">
          <i class="previous"></i>
        </span>
      </li>
      <li class="page-item" v-for="index in computedPages" :key="index">
        <span
          v-if="validationCurrentPage(index)"
          href="#"
          class="page-link m-1"
          :class="{ active: index == paginaAtual }"
          @click="changePage(index)"
          >{{ index }}</span
        >
      </li>
      <li
        class="page-item next m-1"
        :class="{
          disabled: paginaAtual == computedPages.length,
        }"
      >
        <span class="page-link" @click="changePage(paginaAtual + 1)"
          ><i class="next"></i
        ></span>
      </li>
    </ul>
    <div
      v-if="existeBotaoVoltar"
      :class="{ ['card-footer mt-5']: removeFooter }"
    >
      <button class="btn btn-primary" @click.prevent="retornar">Voltar</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import MensagemService from "@/core/services/MensagemService";

export default defineComponent({
  name: "Paginacao-Componente",
  props: {
    paginas: {
      type: Object,
      required: true,
    },
    paginaAtual: {
      type: Number,
      default: 1,
    },
    existeBotaoVoltar: {
      type: Boolean,
      default: false,
    },
    urlDestino: {
      type: String,
      default: "",
    },
    removeFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const computedPages = computed(() => {
      return Array.from(Array(props.paginas.ultimaPagina).keys()).map(
        (x) => x + 1
      );
    });

    const hasPages = computed(() => {
      return Object.entries(props.paginas);
    });

    const loading = ref(true as boolean);
    const current_page = ref(1 as number);

    function validationCurrentPage(index) {
      return (
        (index >= 1 && index <= 3) ||
        (index >= props.paginaAtual - 2 &&
          index <= props.paginaAtual + 2 &&
          index > 3 &&
          index < props.paginas.ultimaPagina - 2) ||
        index >= props.paginas.ultimaPagina - 2 ||
        index == props.paginas.ultimaPagina
      );
    }

    function retornar() {
      MensagemService.retorno({
        texto: "Tem certeza que deseja voltar ?",
        rota: props.urlDestino + (!route?.params?.id ? "" : route?.params?.id),
      });
    }

    function changePage(index) {
      if (index != props.paginaAtual) {
        current_page.value = index;
        emit("set-pagination", current_page.value);
      }
    }

    return {
      computedPages,
      loading,
      current_page,
      validationCurrentPage,
      hasPages,
      changePage,
      retornar,
    };
  },
});
</script>

<style scoped>
th {
  font-weight: bold;
}
.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-link:hover {
  cursor: pointer;
}
</style>
