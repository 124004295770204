//Innyx Component
import {
  InputCustom,
  InputSelect,
  InputDate,
  GradeEdicao,
  MunicipioUf,
  CustomFilterButton,
  TabelaAccordion,
  Tabela,
  TabelaInput,
  Paginacao,
} from "./components";

import BotaoCadastrar from "./components/Botoes/BotaoCadastrar.vue";

export default (app) => {
  const components = {
    InputCustom,
    InputSelect,
    InputDate,
    GradeEdicao,
    MunicipioUf,
    CustomFilterButton,
    TabelaAccordion,
    Tabela,
    TabelaInput,
    Paginacao,
    BotaoCadastrar,
  };

  Object.entries(components).forEach(([name, component]) =>
    app.component(name, component)
  );
};
