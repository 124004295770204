<script setup lang="ts">
import { defineProps, defineEmits, watch, ref } from "vue";
import setup from "../setupComposable/setup";
import commonProps from "../props/commonProps";

const props = defineProps(commonProps);
const emit = defineEmits(["update:modelValue"]);
const { onInput } = setup(emit);
const hidePlacheHolder = ref(false);
watch(props, (props) => {
  if (props.modelValue !== null) {
    if (("" + props.modelValue).length > 0) {
      hidePlacheHolder.value = false;
    }
  }
});
</script>

<template>
  <div>
    <label v-if="$attrs.label" :class="{ required: $attrs.required }">
      {{ $attrs.label }}
    </label>
    <input
      class="form-control"
      :value="modelValue"
      :class="{ 'remove-default-placeholder': hidePlacheHolder }"
      v-bind="$attrs"
      type="date"
      @input="onInput"
    />
  </div>
</template>
<style scoped>
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: -moz-default-background-color;
}

.remove-default-placeholder {
  color: rgba(0, 0, 0, 0);
}
</style>
