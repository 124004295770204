import { PropType } from "vue";

export default {
  dataListTable: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  headerListTable: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  mask: {
    type: String,
    default: "",
  },
  customProps: {
    type: String,
    default: "vagas_def",
  },
  nomeEnsino: {
    type: String,
    default: "",
  },
};
